<template>
  <app-modal title="Create a new project" :width="900" @submit="onSubmit">
    <div class="grid gap-4">
      <app-form-input-text
        v-model="form.project_title"
        hint="Just a project codename, real course title will be defined later"
        label="Project title"
        required
      />

      <course-format-editor
        v-model:content-type="form.content_type"
        v-model:format="form.format"
        :certifying-field="false"
        class="mb-4"
      />

      <teacher-autocomplete
        v-model="form.author.user_id"
        item-key="user_id"
        label="Trainer"
        required
      />

      <app-form-input-text
        v-model="form.briefing_url"
        hint="Notion page dedicated to client briefing"
        label="Briefing page link"
        :rules="{ notionPageUrl: true }"
      />
    </div>
  </app-modal>
</template>

<script lang="ts" setup>
import { defineRule } from "vee-validate";

import type { ProjectCreationRequest } from "~~/model/project.model";

const form = reactive<ProjectCreationRequest>({
  project_title: "",
  author: { user_id: undefined as unknown as DatabaseTable<"users">["id"] },
  briefing_url: undefined as unknown as string,
  format: { duration: "P1D", amount: 1 },
  content_type: "COURSE",
});

function onSubmit() {
  return useModal("projectCreationModal").close({
    confirmed: true,
    payload: form,
  });
}

defineRule("notionPageUrl", (value: string) => {
  if (!value || value.startsWith("https://www.notion.so/")) return true;

  return `Ce champ n'est pas une page Notion valide`;
});
</script>
